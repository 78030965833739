.App {
  font-family: "Courier New", monospace;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0e68c;
  min-height: 100vh;
}

h1 {
  color: #8b0000;
  text-align: center;
}

.chat-interface {
  display: flex;
  margin-bottom: 20px;
}

.harry-image {
  flex: 0 0 150px;
  margin-right: 20px;
}

.harry-image img {
  width: 100%;
  border-radius: 50%;
  border: 3px solid #8b0000;
}

.chat-container {
  flex: 1;
  height: 400px;
  overflow-y: auto;
  border: 2px solid #8b0000;
  padding: 10px;
  background-color: #fff8dc;
}

.message {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}

.user {
  background-color: #ffd700;
  text-align: right;
}

.assistant {
  background-color: #daa520;
}

.input-form {
  display: flex;
}

input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  font-family: "Courier New", monospace;
  border: 2px solid #8b0000;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #8b0000;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Courier New", monospace;
}

button:disabled {
  background-color: #cd5c5c;
}
